import React from "react";

const Menu = () => {
  return (
    <nav id="navbar" class="navbar navbar-expand-lg navbar-light reunir-navbar">
      <div class="container">
        <div class="logo-section">
          <a class="logo-title navbar-brand" href="#">
            <img src="assets/img/nexxture-white-logo.png" alt="logo" />
          </a>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="icofont-navigation-menu"></i>
        </button>
        <div
          class="collapse navbar-collapse nav-main justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav" id="primary-menu">
            <li class="nav-item active">
              <a class="nav-link active" href="/">
                HOME
                <span class="sr-only">(current)</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/">
                ABOUT
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/">
                SERVICES
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/">
                TESTIMONIALS
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/">
                CONTACT
              </a>
            </li>
          </ul>
        </div>
        {/* <div class="right-side-box">
          <a class="join-btn" href="#">
            LOGIN
          </a>
        </div> */}
      </div>
    </nav>
  );
};

export default Menu;
