import React from "react";

const BannerTop = () => {
  return (
    <section class="banner-section">
      <div
        class="overlay"
        style={{ backgroundImage: "url(assets/img/header-bg-3.png)" }}
      >
        <div class="container">
          <div class="total-slide">
            <div class="row text-center">
              <div class="col-lg-12">
                <div class="banner-text">
                  <h1 class="font-light">Take Your</h1>
                  <h1 class="font-bold">Invest Startegy</h1>
                  <h1 class="font-regular">to the next level</h1>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-center">
                <h3 class="banner-bottom-text">
                  A profitable platform for high-margin Equity and Commodity
                  investment
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-center">
                <div class="get-start">
                  <a href="#">GET STARTED NOW!</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="statics-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-sm-6 text-center">
                <div class="single-statics no-border">
                  <div class="icon-box">
                    <i class="ren-people"></i>
                  </div>
                  <div class="text-box">
                    <span class="d-none counter">5000</span>
                    <span class="counter">5000</span>
                    <h4>Active Investors</h4>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6 text-center">
                <div class="single-statics">
                  <div class="icon-box">
                    <i class="ren-reguser"></i>
                  </div>
                  <div class="text-box">
                    <span class="counter">200</span>
                    <h4>Registered Agents</h4>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6 text-center">
                <div class="single-statics">
                  <div class="icon-box">
                    <i class="ren-withdraw"></i>
                  </div>
                  <div class="text-box">
                    <span class="counter">10800000</span>
                    <h4>Payment each month</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerTop;
