import React from "react";

const ContactUsSection = () => {
  return (
    <section class="contact-us-section" id="contact-us-section">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-8 text-center">
            <div class="contact-us-text">
              <h5 class="contact-us-title">Contact Us</h5>
              <h2 class="contact-us-subtitle">Get in Touch</h2>
              <p class="contact-us-title-describe">
                Please fill out this form and we will answer any questions you
                might have. Our door is always open for a discussion with you
                over a cup of coffee.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="contact-img">
              <img src="assets/img/contact-us.jpg" alt="#" />
            </div>
          </div>
          <div class="col-lg-5">
            <div class="contact-form">
              <form id="contactForm" method="post" class="contact-form-aqua">
                <h2 class="contact-head">Get in touch with us!</h2>
                <input
                  type="text"
                  name="name"
                  required=""
                  placeholder="Full Name"
                  class="contact-frm active"
                />
                <input
                  type="email"
                  name="email"
                  required=""
                  placeholder="Email Address"
                  class="contact-frm"
                />
                <input
                  type="text"
                  name="mobile"
                  required=""
                  placeholder="Mobile Number"
                  class="contact-frm"
                />
                <input
                  type="text"
                  name="subject"
                  required=""
                  placeholder="Subject"
                  class="contact-frm"
                />
                <textarea
                  name="message"
                  id="message"
                  placeholder="Message"
                  class="contact-msg"
                ></textarea>
                <input
                  id="form-submit"
                  type="submit"
                  value="SUBMIT NOW"
                  class="contact-btn"
                />
                <br />
                <br />
                <span class="msgSubmit"></span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
