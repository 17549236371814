import React from "react";

import Footer from "../Templete/Footer";
import AboutSection from "./AboutSection";
import AdvantageSection from "./AdvantageSection";
import AppDownloadSection from "./AppDownloadSection";
import BannerTop from "./BannerTop";
import CalculatorSection from "./CalculatorSection";
import CallbackSection from "./CallbackSection";
import ChooseSection from "./ChooseSection";
import ContactUsSection from "./ContactUsSection";
import DepositSection from "./DepositSection";
import Header from "./Header";
import InvestSection from "./InvestSection";
import TestimonialSection from "./TestimonialSection";
import OurServices from "./OurServices";

const Home = () => {
  return (
    <React.Fragment>
      <Header />
      <BannerTop />
      <CalculatorSection />
      <AboutSection />
      <ChooseSection />
      <OurServices />
      <InvestSection />
      <AdvantageSection />
      <DepositSection />
      <CallbackSection />
      <AppDownloadSection />
      <TestimonialSection />
      <ContactUsSection />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
