import React from "react";

const DepositSection = () => {
  return (
    <section class="deposit-section">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-8 text-center">
            <div class="deposit-text">
              <h5 class="deposit-title">Convenient Money</h5>
              <h2 class="deposit-subtitle">Deposit & Withdrawal</h2>
              <p class="deposit-title-describe">
                Depositing or withdrawing money is simple. We support several
                payment methods, which work 24 hours with the highest level of
                security for investors safety.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-5 col-sm-12 reunir-content-center">
            <div class="row d-flex justify-content-start">
              <div class="col-lg-8">
                <div class="payment-methods-outer">
                  <div class="payment-methods">
                    <div class="icon-gallery">
                      <div class="icon-box">
                        <div class="icon-img">
                          <img src="assets/img/card-icon.png" alt="#" />
                        </div>
                        <h5 class="icon-title">Credit Card</h5>
                      </div>
                      <div class="icon-box">
                        <div class="icon-img">
                          <img src="assets/img/card-icon.png" alt="#" />
                        </div>
                        <h5 class="icon-title">Debit Card</h5>
                      </div>
                      <div class="icon-box">
                        <div class="icon-img">
                          <img src="assets/img/upi-logo.png" alt="#" />
                        </div>
                        <h5 class="icon-title">UPI</h5>
                      </div>
                    </div>
                    <div class="icon-gallery">
                      <div class="icon-box">
                        <div class="icon-img">
                          <img src="assets/img/ibank-icon.png" alt="#" />
                        </div>
                        <h5 class="icon-title">Int. Banking</h5>
                      </div>
                      <div class="icon-box">
                        <div class="icon-img">
                          <img src="assets/img/wallet-icon.png" alt="#" />
                        </div>
                        <h5 class="icon-title">Wallet</h5>
                      </div>
                      <div class="icon-box">
                        <div class="icon-img">
                          <img src="assets/img/bank-icon.png" alt="#" />
                        </div>
                        <h5 class="icon-title">Bank Transfer</h5>
                      </div>
                    </div>
                    <div class="link-box">
                      <a href="#">View All Option</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-7 col-sm-12 mt-4">
            <div class="deposit-section-right">
              <div class="icon-box-outer">
                <div class="icon-box">
                  <i class="ren-withdraw2"></i>
                </div>
              </div>
              <div class="icon-text">
                <h2 class="icon-title">No Limit</h2>
                <p class="icon-title-describe">
                  Unlimited maximum withdrawal amount
                </p>
              </div>
            </div>
            <div class="deposit-section-right">
              <div class="icon-box-outer">
                <div class="icon-box icon-box-orange">
                  <i class="ren-deposit5"></i>
                </div>
              </div>
              <div class="icon-text">
                <h2 class="icon-title">Withdrawal in 24 /7</h2>
                <p class="icon-title-describe">Deposit – instantaneous</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DepositSection;
