import React from "react";

const HeaderTop = () => {
  return (
    <div class="header-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 d-flex justify-content-start reunir-content-center">
            <div class="header-left contact-icons">
              <ul>
                <li class="header-left-list">
                  <p class="header-left-text">
                    <span class="header-left-icon"> </span>
                    <a href="tel:+916291876650">
                      <i class="icofont-phone-circle"></i> +91 6291-8766-50
                    </a>
                  </p>
                </li>
                <li class="header-left-list">
                  <p class="header-left-text">
                    <span class="header-left-icon"> </span>
                    <a href="mailto:support@nexxture.org">
                      <i class="icofont-email"></i> support@nexxture.org
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 d-flex justify-content-end reunir-content-center">
            <div class="header-right social-icons">
              <ul>
                <li class="header-right-list">
                  <a href="https://twitter.com">
                    <i class="icofont-facebook"></i>
                  </a>
                </li>

                <li class="header-right-list">
                  <a href="https://facebook.com">
                    <i class="icofont-twitter"></i>
                  </a>
                </li>

                <li class="header-right-list">
                  <a href="https://instagram.com">
                    <i class="icofont-linkedin"></i>
                  </a>
                </li>

                <li class="header-right-list">
                  <a href="https://pinterest.com">
                    <i class="icofont-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
