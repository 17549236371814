import React from "react";

const CallbackSection = () => {
  return (
    <section class="signup-section">
      <div class="overlay">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-8">
              <div class="signup-text">
                <h5 class="signup-title">Need help from the expert?</h5>
                <h2 class="signup-subtitle">We are happy to help you</h2>
                <p class="signup-title-describe">
                  You may place a callback request from here if you need any
                  assistance from Nexxture Trading. Our support team will
                  contact with you as soon as possible during working hours.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 d-flex justify-content-end align-items-center reunir-content-center">
              <div class="signup-right-text">
                <a href="#">
                  Callback Request<i class="ren-arrowright"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallbackSection;
