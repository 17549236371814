import React from "react";
import HeaderTop from "../Templete/HeaderTop";

const Header = () => {
  return (
    <header class="header-section" id="header-section">
      <HeaderTop />

      <nav
        id="navbar"
        class="navbar navbar-expand-lg navbar-light reunir-navbar"
      >
        <div class="container">
          <div class="logo-section">
            <a class="logo-title navbar-brand" href="#">
              <img src="assets/img/nexxture-white-logo.png" alt="logo" />
            </a>
          </div>

          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="icofont-navigation-menu"></i>
          </button>
          <div
            class="collapse navbar-collapse nav-main justify-content-end"
            id="navbarNav"
          >
            <ul class="navbar-nav" id="primary-menu">
              <li class="nav-item active">
                <a class="nav-link active" href="#header-section">
                  HOME
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#about-section">
                  ABOUT
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#our-services-section">
                  SERVICES
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#testimonial-section">
                  TESTIMONIALS
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contact-us-section">
                  CONTACT
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://app.nexxture.in/login"
                  target="_blank"
                >
                  LOGIN
                </a>
              </li>
            </ul>
          </div>
          {/* <div class="right-side-box">
            <a
              class="join-btn"
              href="https://app.nexxture.in/login"
              target="_blank"
            >
              LOGIN
            </a>
          </div> */}
        </div>
      </nav>
    </header>
  );
};

export default Header;
