import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, withRouter } from "react-router-dom";

import Home from "./pages/Home/Home";

import LoadingContext from "./store/loading-context";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";
// import Loading from "./pages/Loading/Loading";

const App = () => {
  // const loadingCtx = useContext(LoadingContext);

  return (
    <React.Fragment>
      {/* {loadingCtx.isLoading && <Loading />} */}

      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route path="terms_and_conditions" element={<TermsAndConditions />} />
          <Route path="privacy_policy" element={<PrivacyPolicy />} />
          <Route path="refund_policy" element={<RefundPolicy />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
