import React from "react";
import { Link } from "react-router-dom";

import "./footer-style.css";

const Footer = () => {
  return (
    <React.Fragment>
      <footer class="footer-section">
        <div class="overlay">
          <div class="waveWrapper waveAnimation">
            <div class="waveWrapperInner bgTop">
              <div class="wave waveTop"></div>
            </div>
            <div class="waveWrapperInner bgMiddle">
              <div class="wave waveMiddle"></div>
            </div>
            <div class="waveWrapperInner bgBottom">
              <div class="wave waveBottom"></div>
            </div>
          </div>

          <div class="footer-content">
            <footer class="footer1">
              <div class="container">
                <div class="row">
                  <div class="col-lg-3 col-md-3">
                    <ul class="list-unstyled clear-margins">
                      <li class="">
                        <img
                          src="assets/img/nexxture-white-logo.png"
                          alt="logo"
                          width="150px"
                        />

                        <p
                          class="text-justify text-white"
                          style={{ paddingTop: "10px" }}
                        >
                          Nexxture is a registered Asset Management company
                          based in Kolkata, India. It is one of the world's most
                          leading Asset Management províder Companies that fixes
                          your route in the ríght dírection, Nexxture is
                          constantly expanding the geography of its activities.
                        </p>

                        <div class="social-icon">
                          <ul class="icon-area">
                            <li class="social-nav">
                              <a href="#">
                                <i class="icofont-facebook"></i>
                              </a>
                            </li>
                            <li class="social-nav">
                              <a href="#">
                                <i class="icofont-twitter"></i>
                              </a>
                            </li>
                            <li class="social-nav">
                              <a href="#">
                                <i class="icofont-pinterest"></i>
                              </a>
                            </li>
                            <li class="social-nav">
                              <a href="#">
                                <i class="icofont-rss"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-3 col-md-3">
                    <ul class="list-unstyled clear-margins">
                      <li class="widget-container widget_nav_menu">
                        <h1 class="title-widget">Services</h1>

                        <ul class="text-white">
                          <li>
                            <a href="#our-services-section" class="text-white">
                              <i class="fa fa-angle-double-right"></i> Equity
                            </a>
                          </li>
                          <li>
                            <a href="#" class="text-white">
                              <i class="fa fa-angle-double-right"></i> Trading
                            </a>
                          </li>
                          <li>
                            <a href="#" class="text-white">
                              <i class="fa fa-angle-double-right"></i> Mutual
                              Fund
                            </a>
                          </li>
                          <li>
                            <a href="#" class="text-white">
                              <i class="fa fa-angle-double-right"></i> Insurance
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-3 col-md-3">
                    <ul class="list-unstyled clear-margins">
                      <li class="widget-container widget_nav_menu">
                        <h1 class="title-widget">Quick links</h1>

                        <ul>
                          <li>
                            <a href="#" class="text-white">
                              <i class="fa fa-angle-double-right"></i> About Us
                            </a>
                          </li>
                          <li>
                            <a href="#" class="text-white">
                              <i class="fa fa-angle-double-right"></i> Contact
                              Us
                            </a>
                          </li>
                          <li>
                            <a href="terms_and_conditions" class="text-white">
                              <i class="fa fa-angle-double-right"></i> Terms &
                              Conditions
                            </a>
                          </li>
                          <li>
                            <a href="privacy_policy" class="text-white">
                              <i class="fa fa-angle-double-right"></i> Privacy
                              Policy
                            </a>
                          </li>
                          <li>
                            <a href="refund_policy" class="text-white">
                              <i class="fa fa-angle-double-right"></i> Refund
                              Policy
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://app.nexxture.in/admin_login"
                              target="_blank"
                              class="text-white"
                            >
                              <i class="fa fa-angle-double-right"></i> Admin
                              Login
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div class="col-lg-3 col-md-3">
                    <ul class="list-unstyled clear-margins">
                      <li class="widget-container widget_recent_news">
                        <h1 class="title-widget">Contact Us </h1>

                        <div class="footerp">
                          <h2 class="title-median">
                            Nexxture Trading Pvt. Ltd.
                          </h2>
                          <p class="text-white">
                            <b>Corp Office: </b>Sajirhat, Madhyamgram, North 24
                            Parganas, Kolkata - 700130, West Bengal, India
                          </p>
                          <p class="text-white">
                            <b>Phone Number : </b>+91 6291-8766-50
                          </p>
                          <p class="text-white">
                            <b>Email ID:</b>{" "}
                            <a
                              href="mailto:support@nexxture.org"
                              class="text-white"
                            >
                              support@nexxture.org
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>

            <div class="footer-bottom">
              <div class="container">
                <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="copyright">
                      Copyright © 2022 Nexxture Trading Private Limited
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="design">
                      <a>Made with ❤️ in India</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      ;
    </React.Fragment>
  );
};

export default Footer;
