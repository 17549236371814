import React from "react";

import Footer from "../Templete/Footer";
import HeaderTop from "../Templete/HeaderTop";
import Menu from "../Templete/Menu";

const RefundPolicy = () => {
  return (
    <React.Fragment>
      {/* <HeaderTop />
      <BannerTop /> */}

      <header class="header-section" id="header-section">
        <HeaderTop />
        <Menu />
      </header>

      <section id="page-cont" class="blog-section">
        <div class="page-breadcrumb">
          <div class="container">
            <div class="breadcrumb-cont">
              <h2>Refund Policy</h2>
              <ul>
                <li>
                  <a href="">
                    <i class="icofont-home"></i> Home
                  </a>
                </li>
                <li>
                  <small>/</small>
                </li>
                <li>Refund Policy</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="blog-wrap">
          <div class="container">
            <h2>
              <strong>Refund Policy</strong>
            </h2>

            <br />

            <h2>Nexxture Trading liability for refund :</h2>
            <p>
              1. We will refund the amount in case a transaction is failed due
              to any reason directly caused by Nexxture Trading. Once Nexxture
              Trading receives a relevant confirmation from the payment gateway,
              a proper refund will be processed to the user’s bank account and
              it will take 3-21 working days to reflect from the date of
              transaction. Confirmation about the same will be notified to the
              User’s registered Email ID. Kindly note, a refund will be
              processed only for the transaction amount, and not for any payment
              gateway charges or applicable taxes.
            </p>
            <p>
              2. We will refund the amount in case the user has paid for some
              services, got confirmation about the payment but does not receive
              those services to utilize. In such cases, we request the user to
              drop us a complaint on our official Email ID and let us evaluate
              the scenario. Once we investigate and make a conclusion, we will
              refund your amount to your bank account
            </p>

            <h2>Nexxture Trading non-liability for refund :</h2>
            <p>
              1. We will not be liable for any refund if a transaction is failed
              due to network error, electricity issues, or any other such
              reasons. Nexxture Trading will not be responsible for any failure
              caused due to irrelevant and invalid reasons.
            </p>
            <p>
              2. We will not be liable for any refund after the purchase. Once
              the user agrees to use our services and conducts the payment,
              Nexxture Trading will not be responsible for any refund.
            </p>
            <p>
              3. We will not be liable for any refund if the user fails to
              perform KYC at the initial stage. Each Nexxture Trading user has
              to go through a successful KYC verification once. Thus, it is
              mandatory to keep the correct KYC documents ready before
              initiating any payment to Nexxture Trading.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default RefundPolicy;
