import React from "react";

const AdvantageSection = () => {
  return (
    <section class="advantage-section">
      <div class="overlay">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <div class="advantage-text">
                <h5 class="advantage-title">Our Biggest</h5>
                <h2 class="advantage-subtitle">Advantage</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 text-center">
              <div class="single-box">
                <div class="icon-box">
                  <i class="ren-team"></i>
                </div>
                <div class="text-box">
                  <h2>PROFESSIONAL TEAM</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 text-center">
              <div class="single-box">
                <div class="icon-box">
                  <i class="ren-profitable-plan"></i>
                </div>
                <div class="text-box">
                  <h2>PROFITABLE PLANS</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 text-center">
              <div class="single-box">
                <div class="icon-box">
                  <i class="ren-control-panel"></i>
                </div>
                <div class="text-box">
                  <h2>Secure Control Panel</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvantageSection;
