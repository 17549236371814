import React from "react";

const AppDownloadSection = () => {
  return (
    <section class="download-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-7">
            <div class="download-text">
              <h5 class="download-title">Learn, Plan, Invest in our app</h5>
              <h2 class="download-subtitle">Our Smart App</h2>
              <p class="download-title-describe">
                Our application is a set of tools designed to facilitate making
                money.
              </p>
            </div>
            <div class="app-features">
              <div class="single-features">
                <div class="icon-box-outer">
                  <div class="icon-box">
                    <i class="ren-calculator1"></i>
                  </div>
                </div>
                <h3 class="single-features-text">
                  Calculators to plan your investments
                </h3>
              </div>
              <div class="single-features">
                <div class="icon-box-outer">
                  <div class="icon-box">
                    <i class="ren-paperless"></i>
                  </div>
                </div>
                <h3 class="single-features-text">
                  100% paperless process to invest in the best funds
                </h3>
              </div>
              <div class="single-features">
                <div class="icon-box-outer">
                  <div class="icon-box">
                    <i class="ren-dashboard"></i>
                  </div>
                </div>
                <h3 class="single-features-text">
                  Dashboard to track your progress
                </h3>
              </div>
            </div>
            <div style={{ paddingTop: "30px" }}>
              <a href="#" class="btn">
                <img
                  src="assets/img/google-play.png"
                  alt="google-play-icon"
                  width="200px"
                />
              </a>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="right-area">
              <img
                class="ellipse-01"
                src="assets/img/download-bg-ellipse-01.png"
                alt="#"
              />
              <img
                class="ellipse-02"
                src="assets/img/download-bg-ellipse-02.png"
                alt="#"
              />
              <img
                class="ellipse-03"
                src="assets/img/download-bg-ellipse-03.png"
                alt="#"
              />
              <img
                class="ellipse-04"
                src="assets/img/download-bg-ellipse-04.png"
                alt="#"
              />
              <img
                class="smart-phone"
                src="assets/img/mobile-app.png"
                alt="#"
                style={{ width: "70%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppDownloadSection;
