import React, { useState } from "react";

const CalculatorSection = () => {
  const [amount, setAmount] = useState(5000);
  const [percentage, setPercentage] = useState(1);

  const [monthly, setMonthly] = useState(0);
  const [daily, setDaily] = useState(0);
  const [weekly, setWeekly] = useState(0);

  const amountHandler = (value) => {
    setAmount(value);
    handler(value, percentage);
  };

  const percentageHandler = (value) => {
    setPercentage(value);
    handler(amount, value);
  };

  const handler = (amount) => {
    const monthly = parseFloat((percentage / 100) * amount);

    console.log("amount" + amount);
    console.log("percentage" + percentage);
    console.log("month comm" + monthly);
    const daily = parseFloat(monthly / 30);
    const weekly = parseFloat(monthly / 4);

    setDaily(daily);
    setMonthly(monthly);
    setWeekly(weekly);
  };

  return (
    <section class="calculate-aria">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-10">
            <div class="calculate-left">
              <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-2">
                  <div class="icon-box">
                    <i class="ren-calculator"></i>
                  </div>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-10">
                  <div class="form-group">
                    <h2 class="amount">Enter Investment Amount</h2>
                    <div class="input-dropdown">
                      <input
                        type="text"
                        name="text"
                        id="amount"
                        placeholder="₹100000"
                        class="main-form calculator-invest"
                        onChange={(e) => {
                          amountHandler(parseFloat(e.target.value));
                        }}
                        // onInput={}
                      />
                      <div class="form-dropdown">
                        <select
                          class="form-btn-dropdown"
                          id="percentage"
                          onChange={(e) => {
                            percentageHandler(parseFloat(e.target.value));
                          }}
                        >
                          <option value="1">1% Monthly For Ever</option>
                          <option value="2">2% Monthly For Ever</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-10 text-center">
            <div class="calculate-right">
              <div class="row justify-content-end">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="text-box">
                    <span
                      class="counter calculator-result-daily"
                      id="daily-result"
                    >
                      {daily}
                    </span>
                    <h4>Daily Profit</h4>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="text-box">
                    <span
                      class="counter calculator-result-weekly"
                      id="weekly-result"
                    >
                      {weekly}
                    </span>
                    <h4>Weekly Profit</h4>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div class="text-box">
                    <span
                      class="counter calculator-result-monthly"
                      id="monthly-result"
                    >
                      {monthly}
                    </span>
                    <h4>Monthly Profit</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CalculatorSection;
