import React from "react";

import "./OurServicesStyle.css";

const OurServices = () => {
  return (
    <section class="services-section" id="our-services-section">
      <div class="overlay">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-8 text-center">
              <div class="invest-text">
                <h5 class="invest-title">Our Professional</h5>
                <h2 class="invest-subtitle">Services</h2>
                <p class="invest-title-describe">
                  It’s a simple way to start invest.You don’t need a deep wallet
                  to start behoof. Pick an amount you can afford, and build your
                  behoof over time.
                </p>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-md-3 col-sm-6">
                <div class="serviceBox">
                  <div class="service-icon">
                    <span>
                      <i class="fa fa-globe"></i>
                    </span>
                  </div>
                  <h3 class="title">Equity</h3>
                  <p class="description">
                    Trade sleekly in the market with your credible investment
                    partner. Get timely custom advice to make the right decision
                    at the right time.
                  </p>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="serviceBox">
                  <div class="service-icon">
                    <span>
                      <i class="fa fa-rocket"></i>
                    </span>
                  </div>
                  <h3 class="title">Commodities</h3>
                  <p class="description">
                    Our comprehensive study of the market and expertise enable
                    our clients to take precise decisions when it comes to
                    commodity trading.
                  </p>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="serviceBox">
                  <div class="service-icon">
                    <span>
                      <i class="fa fa-rocket"></i>
                    </span>
                  </div>
                  <h3 class="title">Mutual Funds</h3>
                  <p class="description">
                    Leverage on our expert advisory for right allocation of your
                    funds and magnified returns.
                  </p>
                </div>
              </div>
              <div class="col-md-3 col-sm-6">
                <div class="serviceBox">
                  <div class="service-icon">
                    <span>
                      <i class="fa fa-rocket"></i>
                    </span>
                  </div>
                  <h3 class="title">Insurance</h3>
                  <p class="description">
                    Get the best suitable insurance plan in consideration to
                    your need and relevance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
