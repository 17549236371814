import React from "react";

const InvestSection = () => {
  return (
    <section class="invest-section" id="invest-section">
      <div class="overlay">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-8 text-center">
              <div class="invest-text">
                <h5 class="invest-title">How You Can Invest Your</h5>
                <h2 class="invest-subtitle">Money More Smartly</h2>
                <p class="invest-title-describe">
                  It’s a simple way to start invest.You don’t need a deep wallet
                  to start behoof. Pick an amount you can afford, and build your
                  behoof over time.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 text-center reunir-content-center">
              <div class="single-box location-left">
                <div class="img-box">
                  <div class="font-side">
                    <img src="assets/img/sign-up.png" alt="#" />
                  </div>
                  <div class="back-side">
                    <img src="assets/img/sign-up.png" alt="#" />
                  </div>
                </div>
                <div class="text-box">
                  <a href="#">
                    FIRST STEP<i class="ren-arrowright"></i>
                  </a>
                  <h3>SING UP</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 text-center reunir-content-center">
              <div class="single-box">
                <div class="img-box">
                  <div class="font-side">
                    <img src="assets/img/deposit.png" alt="#" />
                  </div>
                  <div class="back-side">
                    <img src="assets/img/deposit.png" alt="#" />
                  </div>
                </div>
                <div class="text-box">
                  <a href="#">
                    SECOND STEP<i class="ren-arrowright"></i>
                  </a>
                  <h3>Make a Deposit</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 text-center reunir-content-center">
              <div class="single-box location-right">
                <div class="img-box">
                  <div class="font-side">
                    <img src="assets/img/withdraw-1.png" alt="#" />
                  </div>
                  <div class="back-side">
                    <img src="assets/img/withdraw-1.png" alt="#" />
                  </div>
                </div>
                <div class="text-box">
                  <a href="#">
                    THIRD STEP<i class="ren-arrowright"></i>
                  </a>
                  <h3>Withdraw</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestSection;
