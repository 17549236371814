import React from "react";

const AboutSection = () => {
  return (
    <section class="about-section navigation" id="about-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="about-left">
              <div class="about-text">
                <h2 class="about-subtitle">WELCOME TO NEXXTURE TRADING</h2>

                <p class="about-description">
                  Nexxture is a registered Asset Management company in India.
                  Our Headquarter is located at Sajrhat, Madhyamgram, North 24
                  Parganas, Kolkata 700130. It is one of the world's most
                  leading Asset Management províder Companies that fixes your
                  route in the ríght dírection, Nexxture is constantly expanding
                  the geography of its activities. Our hands with experience and
                  essential technical skills will help you reach the heights.
                  The conmpany combines several successful profitable business
                  oicections from Multi Trading Platform, Traditional Business.
                  Our advanced team, design and develop the plans & strategies
                  of business in such a way that can be helped you to earn & get
                  he success goal with much ease.
                </p>
              </div>

              <div class="about-box">
                <div class="row text-center">
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="single-about-box">
                      <div class="icon-box-outer bg-eff">
                        <div class="icon-box">
                          <i class="ren-efficiency"></i>
                        </div>
                      </div>
                      <h3>Efficiency</h3>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="single-about-box">
                      <div class="icon-box-outer bg-ex">
                        <div class="icon-box">
                          <img src="assets/img/expierence.svg" alt="#" />
                        </div>
                      </div>
                      <h3>Expierence</h3>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="single-about-box">
                      <div class="icon-box-outer bg-security">
                        <div class="icon-box">
                          <i class="ren-security"></i>
                        </div>
                      </div>
                      <h3>Security</h3>
                    </div>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="single-about-box">
                      <div class="icon-box-outer bg-trans">
                        <div class="icon-box">
                          <i class="ren-transparent"></i>
                        </div>
                      </div>
                      <h3>Transparent</h3>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="single-about-box">
                      <div class="icon-box-outer bg-simple">
                        <div class="icon-box">
                          <i class="ren-simple"></i>
                        </div>
                      </div>
                      <h3>Simple</h3>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="single-about-box">
                      <div class="icon-box-outer bg-com">
                        <div class="icon-box">
                          <i class="ren-compliant"></i>
                        </div>
                      </div>
                      <h3>Compliant</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="about-right">
              <div class="video-box">
                <img src="assets/img/about-bg-2.png" alt="#" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
