import React from "react";

const ChooseSection = () => {
  return (
    <section class="choose-section">
      <div class="overlay">
        <div class="container-fruit text-center">
          <div class="row mr-0 ml-0 d-flex justify-content-center">
            <div class="col-xl-8 col-lg-12">
              <div class="choose-text">
                <h5 class="choose-title">Boost Your Money</h5>
                <h2 class="choose-subtitle">Why Should Choose Us?</h2>
                <p class="choose-title-describe">
                  Our service gives you better result and savings, as per your
                  requirement and you can manage your investments from anywhere
                  either form home or work place, at any time.
                </p>
              </div>
            </div>
          </div>

          <div class="choose-section-carousel">
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/daily-income.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Daily Income</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/withdraw1.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">FASTEST PAYMENTS</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/invest-fild.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Easy to Use</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/security.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">HIGH SECURITY</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/customer-service.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">24/7 Support</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/daily-income.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Monthly Income</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/withdraw1.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">FASTEST PAYMENTS</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/invest-fild.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Easy to Use</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/security.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">HIGH SECURITY</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/customer-service.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">24/7 Support</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/daily-income.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Monthly Income</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/withdraw1.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">FASTEST PAYMENTS</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/invest-fild.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Easy to Use</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/security.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">HIGH SECURITY</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/customer-service.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">24/7 Support</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/daily-income.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Monthly Income</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/withdraw1.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">FASTEST PAYMENTS</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/invest-fild.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Easy to Use</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/security.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">HIGH SECURITY</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/customer-service.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">24/7 Support</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/daily-income.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Monthly Income</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/withdraw1.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">FASTEST PAYMENTS</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/invest-fild.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Easy to Use</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/security.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">HIGH SECURITY</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/customer-service.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">24/7 Support</h2>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/daily-income.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Monthly Income</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/withdraw1.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">FASTEST PAYMENTS</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/invest-fild.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">Easy to Use</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/security.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">HIGH SECURITY</h2>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="single-item">
                <div class="icon-box">
                  <img src="assets/img/customer-service.png" alt="#" />
                </div>
                <div class="text-box">
                  <h2 class="single-item-title">24/7 Support</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseSection;
