import React from "react";

const TestimonialSection = () => {
  return (
    <section class="testimonial-section" id="testimonial-section">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-8">
            <div class="testimonial-text text-center">
              <h5 class="testimonial-title">Happy Clients</h5>
              <h2 class="testimonial-subtitle">Testimonial of Clients</h2>
              <p class="testimonial-title-describe">
                We have many happy investors invest with us .Some impresions
                from our Customers! Please read some of the lovely things our
                Customers say about us.
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-6">
            <div class="testimonial-carousel">
              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “I'm associated with them for more than 5 years now.
                    Reliable management and best in class services. Highly
                    recommended.’’
                  </p>
                  <h2 class="client-name">Rakesh Sharma</h2>
                  <h4 class="client-date">Kolkata, India</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “Excellent investment services with appropriate financial
                    plans for investments for future benefits.’’
                  </p>
                  <h2 class="client-name">Ramachandra Mohanti</h2>
                  <h4 class="client-date">Kolkata, India</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “I highly recommend Nexxture. Their team is professional,
                    knowledgeable, and dedicated to achieving their client's
                    financial goals.’’
                  </p>
                  <h2 class="client-name">Koushik Das</h2>
                  <h4 class="client-date">Barasat, Kolkata</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “I highly recommend doing trading activities at Nexxture to
                    my friends and relatives. I am really pleased with their
                    quick response in taking orders and delivering them.’’
                  </p>
                  <h2 class="client-name">Arnab Chatterjee</h2>
                  <h4 class="client-date">Barasat, Kolkata</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “Highly satisfied with their simple and easy-to-trade
                    solutions. It helps me to decide on trading and saves
                    time.’’
                  </p>
                  <h2 class="client-name">Soumajit Roy</h2>
                  <h4 class="client-date">Barasat, Kolkata</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “We are very much satisfied with Nexxture Services, the best
                    part is they are suggested all the products as per clients
                    requirements.’’
                  </p>
                  <h2 class="client-name">Hafijur Rahaman</h2>
                  <h4 class="client-date">Barasat, Kolkata</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="testimonial-carousel">
              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “I'm associated with them for more than 5 years now.
                    Reliable management and best in class services. Highly
                    recommended.’’
                  </p>
                  <h2 class="client-name">Rakesh Sharma</h2>
                  <h4 class="client-date">Kolkata, India</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “Excellent investment services with appropriate financial
                    plans for investments for future benefits.’’
                  </p>
                  <h2 class="client-name">Ramachandra Mohanti</h2>
                  <h4 class="client-date">Kolkata, India</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “I highly recommend Nexxture. Their team is professional,
                    knowledgeable, and dedicated to achieving their client's
                    financial goals.’’
                  </p>
                  <h2 class="client-name">Koushik Das</h2>
                  <h4 class="client-date">Barasat, Kolkata</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “I highly recommend doing trading activities at Nexxture to
                    my friends and relatives. I am really pleased with their
                    quick response in taking orders and delivering them.’’
                  </p>
                  <h2 class="client-name">Arnab Chatterjee</h2>
                  <h4 class="client-date">Barasat, Kolkata</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “Highly satisfied with their simple and easy-to-trade
                    solutions. It helps me to decide on trading and saves
                    time.’’
                  </p>
                  <h2 class="client-name">Soumajit Roy</h2>
                  <h4 class="client-date">Barasat, Kolkata</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>

              <div class="carousel-item">
                <div class="carousel-caption">
                  <p class="client-describe">
                    “We are very much satisfied with Nexxture Services, the best
                    part is they are suggested all the products as per clients
                    requirements.’’
                  </p>
                  <h2 class="client-name">Hafijur Rahaman</h2>
                  <h4 class="client-date">Barasat, Kolkata</h4>
                </div>
                <div class="carousel-img">
                  <div class="img-outline">
                    <img src="assets/img/table-img1.png" alt="#" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
